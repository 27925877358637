<template>
  <div class="error-404-container">
    <img src="@/assets/images/404.svg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
